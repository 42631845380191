
























import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { accountNumberGridActionList } from '@/utils/gridActions/accountNumberGridActionList';
import {
  accountNumberRouteEdit,
  accountNumberRouteNew,
  accountNumberRouteShow,
} from '@/views/definitions/accountNumber/routes';
import { MAccountNumber } from '@/models/MAccountNumber';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'AccountNumberShow',

  components: {
    BaseGridTable,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      gridActionList: accountNumberGridActionList,

      gridColumnDefinition: [
        {
          headerName: this.$t('accountNumber.property.name'),
          field: 'name',
        },
        {
          headerName: this.$t('accountNumber.property.tax'),
          field: 'tax',
        },
        {
          headerName: this.$t('accountNumber.property.accounting'),
          field: 'accounting',
        },
        {
          headerName: this.$t('accountNumber.property.accountType'),
          field: 'accountType',
        },
        {
          headerName: this.$t('accountNumber.property.taxType'),
          field: 'taxType',
        },
      ],
    };
  },

  computed: {
    accountNumberList(): Array<MAccountNumber> {
      return MAccountNumber.all().sort((a, b) => a.AccountingNumber - b.AccountingNumber);
    },

    gridRowData(): Array<any> {
      return this.accountNumberList.map(
        accountNumber => accountNumber.asJson,
      );
    },
  },

  methods: {
    add(): void {
      this.$router.push(accountNumberRouteNew(this.$i18n.locale));
    },

    closeModal(): void {
      this.$router.push(accountNumberRouteShow(this.$i18n.locale));
    },

    edit(ID: string): void {
      this.$router.push({
        name: accountNumberRouteEdit(this.$i18n.locale).name,

        params: {
          ID: ID,
        },
      });
    },

    async remove(ID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$t('accountNumber.alert.delete', {
          name: MAccountNumber.find(ID).Name,
        }),
      );

      if (confirmation.value) {
        MAccountNumber._delete(ID);
      }
    },
  },
});
