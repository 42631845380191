import { modes } from '@/utils/TModes';

export const mixinModes = {
  props: {
    mode: {
      type: String,
      default: modes.EDIT,
    },
  },

  computed: {
    appending(): boolean {
      return this.mode === modes.APPEND;
    },

    creating(): boolean {
      return this.mode === modes.NEW;
    },

    editing(): boolean {
      return this.mode === modes.EDIT;
    },
  },
};
