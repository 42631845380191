
























































































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { accountingList, accountTypeList, taxTypeList } from '@/utils/accounting';
import { MAccountNumber } from '@/models/MAccountNumber';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MTax } from '@/models/MTax';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'AccountNumberEdit',

  components: {
    BaseInputField,
    BaseSelect,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      accountingList,
      accountTypeList,
      taxTypeList,
    };
  },

  computed: {
    currentAccountNumber: {
      get(): MAccountNumber {
        return MAccountNumber.find(this.ID);
      },

      async set(accountNumber: MAccountNumber) {
        this.dirty = true;

        await MAccountNumber.update({
          where: this.ID,

          data: accountNumber,
        });
      },
    },

    currentAccountNumberAccounting() {
      if (utIsEmpty(this.currentAccountNumber.Accounting)) {
        return {};
      }

      return {
        Id: this.currentAccountNumber.Accounting,
        Name: this.$t(`accountNumber.accounting.${this.currentAccountNumber.Accounting}`),
      };
    },

    currentAccountNumberAccountType() {
      if (utIsEmpty(this.currentAccountNumber.AccountType)) {
        return {};
      }

      return {
        Id: this.currentAccountNumber.AccountType,
        Name: this.$t(`accountNumber.accountType.${this.currentAccountNumber.AccountType}`),
      };
    },

    currentAccountNumberTaxType() {
      if (utIsEmpty(this.currentAccountNumber.TaxType)) {
        return {};
      }

      return {
        Id: this.currentAccountNumber.TaxType,
        Name: this.$t(`accountNumber.taxType.${this.currentAccountNumber.TaxType}`),
      };
    },

    taxList() {
      return MTax.all();
    },
  },

  methods: {
    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$t('accountNumber.alert.delete', {
          name: this.currentAccountNumber.Name,
        }),
      );

      if (confirmation.value) {
        await MAccountNumber._delete(this.ID);

        this.closeModal();
      }
    },

    sync(): void {
      MAccountNumber._update(this.currentAccountNumber);
    },

    updateAccountNumber(Prop, Value): void {
      if (this.currentAccountNumber[Prop] !== Value) {
        this.currentAccountNumber = {
          ...this.currentAccountNumber,
          [`${Prop}`]: Value,
        };
      }
    },
  },
});
