

























import BaseSvgIcon from './BaseSvgIcon.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    BaseSvgIcon,
  },

  props: {
    columnCount: {
      type: Number,
      default: 1,
    },

    customColumnTemplate: {
      type: [
        String,
        Boolean,
      ],
      default: false,
    },

    icon: {
      type: [
        Boolean,
        String,
      ],
      default: false,
    },

    label: {
      required: true,
      type: String,
    },
  },

  computed: {
    /* In most cases the columns would be the same size. However, this would allow for easy edge case handling */
    gridTemplateColumnsStyle() {
      return this.customColumnTemplate === false
        ? `grid-template-columns: repeat(${this.columnCount}, 1fr)`
        : `grid-template-columns: ${this.customColumnTemplate}`;
    },
  },
});
