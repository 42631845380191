








































































import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseFlyOut from '@/components/base/BaseFlyOut.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import Vue, { VueConstructor } from 'vue';
import { CONTEXT_NAME } from '@/utils/contextName';
import { IAction } from '@/typings/interface/IAction';
import { mixinModes } from '@/components/mixins/modes';
import { POSITION } from '@/utils/positions';

export default (Vue as VueConstructor).extend({
  name: 'LayoutModalEdit',

  components: {
    BaseButtonIcon,
    BaseButtonText,
    BaseFlyOut,
    BaseLoader,
    BaseModal,
  },

  mixins: [
    mixinModes,
  ],

  props: {
    actionList: {
      type: Array as () => Array<IAction>,
      default: function() {
        return [
          {
            event: 'remove',
            icon: 'trashcan',
            label: 'common.action.delete',
            name: 'remove',
          },
        ];
      },
    },

    useFlyOut: {
      type: Boolean,
      default: true,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    heading: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isValid: {
      type: Boolean,
      default: true,
    },

    withControlls: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      CONTEXT_NAME,
      POSITION,
    };
  },
});
