import { i18n } from '@/locales/i18n';

export enum Accounting {
  EARNINGS = 'earnings',
  EXPENSES = 'expenses',
}

const locale = localStorage.getItem('locale');

export const accountingList = [
  {
    Id: Accounting.EARNINGS,
    Name: String(i18n(locale).t(`accountNumber.accounting.${Accounting.EARNINGS}`)),
  },
  {
    Id: Accounting.EXPENSES,
    Name: String(i18n(locale).t(`accountNumber.accounting.${Accounting.EXPENSES}`)),
  },
];

export enum AccountType {
  BANK = 'bank',
  CASH = 'cash',
  EFFECTLESS = 'effectless',
  NORMAL = 'normal',
}

export const accountTypeList = [
  {
    Id: AccountType.BANK,
    Name: String(i18n(locale).t(`accountNumber.accountType.${AccountType.BANK}`)),
  },
  {
    Id: AccountType.CASH,
    Name: String(i18n(locale).t(`accountNumber.accountType.${AccountType.CASH}`)),
  },
  {
    Id: AccountType.EFFECTLESS,
    Name: String(i18n(locale).t(`accountNumber.accountType.${AccountType.EFFECTLESS}`)),
  },
  {
    Id: AccountType.NORMAL,
    Name: String(i18n(locale).t(`accountNumber.accountType.${AccountType.NORMAL}`)),
  },
];

export enum TaxTypeList {
  ICPURCHASE = 'icpurchase',
  NORMAL = 'normal',
  NOSALES = 'nosales',
  REVERSECHARGE = 'reversecharge',
}

export const taxTypeList = [
  {
    Id: TaxTypeList.ICPURCHASE,
    Name: String(i18n(locale).t(`accountNumber.taxType.${TaxTypeList.ICPURCHASE}`)),
  },
  {
    Id: TaxTypeList.NORMAL,
    Name: String(i18n(locale).t(`accountNumber.taxType.${TaxTypeList.NORMAL}`)),
  },
  {
    Id: TaxTypeList.NOSALES,
    Name: String(i18n(locale).t(`accountNumber.taxType.${TaxTypeList.NOSALES}`)),
  },
  {
    Id: TaxTypeList.REVERSECHARGE,
    Name: String(i18n(locale).t(`accountNumber.taxType.${TaxTypeList.REVERSECHARGE}`)),
  },
];
