













































import BaseGrid from '@/components/base/grid/BaseGrid.vue';
import BaseGridFooter from '@/components/base/grid/BaseGridFooter.vue';
import BaseGridHeader from '@/components/base/grid/BaseGridHeader.vue';
import Vue, { VueConstructor } from 'vue';
import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';
import { size } from '@/assets/styles/base/variables/size/size';
import { utIsEmpty } from '@/utils/empty';

interface IBaseGrid {
  draggable: boolean,
  rowData: any,
}

export default (Vue as VueConstructor<Vue & IBaseGrid>).extend({
  name: 'BaseGridTable',

  components: {
    BaseGridFooter,
    BaseGridHeader,
  },

  extends: BaseGrid,

  props: {
    draggable: {
      default: false,
      type: Boolean,
    },

    frameworkComponents: {
      type: Object,
      default: function() {
        return {};
      },
    },

    headerHeading: {
      type: String,
      default: '',
    },

    loadingOverlayComponent: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hideHeaderRow: !this.draggable ? utIsEmpty(this.rowData) : false,

      isBaseGridDraggable: this.draggable,
    };
  },

  watch: {
    rowData: {
      deep: true,
      async handler(): Promise<any> {
        this.hideHeaderRow = utIsEmpty(this.rowData);

        this.gridOptions.headerHeight = this.hideHeaderRow ? 0 : size.tappableSquare;

        this.columnDefs[0].sortable = false;

        if (this.gridApi !== null) {
          this.gridApi.refreshHeader();
        }
      },
    },
  },

  methods: {
    onRowClick(event): void {
      if (
        !_isUndefined(this.columnDefinition[0]?.cellRendererFramework) ||
        !_isUndefined(this.columnDefinition[2]?.cellRendererFramework) ||
        !_isUndefined(this.columnDefinition[4]?.cellRendererFramework)
      ) {
        if (event?.action) {
          this.$emit(`click:${event.action}`, event.Id);
        } else {
          if (event.target.tagName !== 'BUTTON') {
            this.$emit('click:row', event.target.getAttribute('row-id'));
          }
        }
      } else {
        this.$emit('click:row', event.target.getAttribute('row-id'));
      }
    },
  },

  beforeMount(): void {
    if (!_isArray(this.columnDefinition[0].cellClass)) {
      this.columnDefinition[0].cellClass = [
        this.columnDefinition[0].cellClass,
      ];
    }

    this.columnDefinition[0].cellClass = [
      ...this.columnDefinition[0].cellClass,
      'no-border-padding',
    ];

    if (!_isArray(this.columnDefinition[0].headerClass)) {
      this.columnDefinition[0].headerClass = [
        this.columnDefinition[0].headerClass,
      ];
    }

    this.columnDefinition[0].headerClass = [
      ...this.columnDefinition[0].headerClass,
      'no-border-padding',
    ];
  },
});
