export const mixinSync = {
  data() {
    return {
      dirty: false,
    };
  },

  methods: {
    sync(): void {
      throw new Error('When using mixinSync you must implement sync() method.');
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      this.sync();
    }

    next();
  },
};
